<template>

    <div class="on-boarding-steps">

        <transition name="fade" mode="out-in">
            <card v-if="currentStep"
                  :class="{'hidden' : (currentStep.step === 3)}"  
                  :clouds="{ left: { enabled: true, pinTo: 'top' }, right: { enabled: true, pinTo: 'bottom' } }"
                  :key="'card_' + currentStep.step">
                <component :is="currentStep.component"></component>
            </card>
        </transition>

        <div class="nav" ref="nav">
            <o2-button @click.native="nextStepClickHandler"
                        :label="(currentStep.step === steps.length) ? labels.continue : labels.next"
                        icon="arrow"
                        ref="buttonElement"></o2-button>
            <div v-show="(currentStep.step < steps.length)" class="mt-1">
                <a @click="handleSkipClick" class="skip-link">{{ labels.skip }}</a>
            </div>
            <ul class="step-nav mt-2 mr-2">
                <li v-for="(step, idx) in steps"
                    :key="'step-nav_' + idx"
                    :class="{ 'active': (currentStep.step === (idx + 1)) }"
                    @click="stepNavClickHandler(step.step)"></li>
            </ul>
        </div>
        
    </div>

</template>


<script>

import { mapGetters, mapActions } from 'vuex'
import { SET_CURRENT_STEP } from '@/store/oxygenCalculator/actions'

import Card from '@/components/oxygen-calculator/Card'
import O2Button from './O2Button'

export default {

    name: 'OnBoardingSteps',

    components: {
        Card,
        O2Button
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            labels: {
                next: 'Next',
                skip: 'Skip',
                continue: 'Continue'
            }
        };
    },

    computed: {

        ...mapGetters('OxygenCalculator', {
            steps: 'onBoardingSteps',
            currentStep: 'currentStep'
        }),

        totalSteps() {
            return this.steps.length
        }

    },
    
    methods: {

        /**
         * @function handleSkipClick
         * @description
         *
         */
        handleSkipClick() {
            this.$router.push({ name: 'OxygenCalculatorWidget' })
        },

        /**
         * @function nextStepClickHandler
         * @description
         */
        nextStepClickHandler() {
            if (this.currentStep.step < this.totalSteps) {
                this.setCurrentStep(this.steps[this.currentStep.step]);
                this.$refs.nav.style.opacity = 0
                let here = this
                let t_out = setTimeout(function(){
                    clearTimeout(t_out)
                    here.$refs.nav.style.opacity = 1
                },800)
            } else {
                this.handleSkipClick()
            } 
        },

        /**
         * @function stepNavClickHandler
         * @description
         * @param {Number} nextStep
         */
        stepNavClickHandler(nextStep){
            if (nextStep <= this.totalSteps) {
                this.setCurrentStep(this.steps[nextStep-1]);
                this.$refs.nav.style.opacity = 0
                let here = this
                let t_out = setTimeout(function(){
                    clearTimeout(t_out)
                    here.$refs.nav.style.opacity = 1
                },800)
            }
        },

        ...mapActions('OxygenCalculator', {
            setCurrentStep: SET_CURRENT_STEP
        })

    },

    beforeDestroy() {
        this.setCurrentStep(null);
    }

}

</script>


<style lang="scss" scoped>

.card {
    margin: 0 20px 50px;
    max-width: 850px;

    @media (min-width: 576px) { // RED (SM)
        margin: 0 50px 50px;
    }
    @media (min-width: 768px) { // GREEN (MD)
        margin: 0 50px 50px;
    }
    @media (min-width: 992px) { // BLUE (LG)
        margin: 0 auto 50px;
    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }

    &.hidden {
        max-width: 100%;
        padding: 0;
    }
}

.on-boarding-steps {
    position: relative;

    & .skip-link {
        color: #5db0d6;
        font-size: 20px;;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    & button {
        margin-top: -30px;

        @media (min-width: 576px) { // RED (SM)
            margin-top: 0;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }
}

.step-nav {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0rem;

    &::before {
        content: '';
        background: #5db0d6;
        width: 17px;
        height: 2px;
        position: relative;
    }

    & > li {
        background: white;
        width: 13px;
        height: 13px;
        border: 2px solid #5db0d6;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 7px;

        &::before {
            content: '';
            background: #5db0d6;
            width: 7px;
            height: 2px;
            position: relative;
            left: -9px;
        }

        &.active {
            background: #5db0d6;
        }
    }
}

</style>
