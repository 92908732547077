<template>

    <div class="oxygen-calculator-on-boarding">

        <on-boarding-steps></on-boarding-steps>
        
    </div>

</template>


<script>

import OnBoardingSteps from '@/components/oxygen-calculator/OnBoardingSteps'

export default {

    name: 'OxygenCalculatorOnBoarding',

    components: {
        OnBoardingSteps
    },

    data() {
        return {
            componentName: this.$options.name + ' View'
        }
    },

    computed: {

    },
    
    created() {
        this.$emit('hide::landingContent')
    },
    
    mounted() {
        
    },
    
    methods: {

    }
    
}

</script>


<style lang="scss" scoped>

.oxygen-calculator-on-boarding {
    padding: 0;
    margin-top: 25px;
    position: relative;
    z-index: 25;
    transform: translateZ(1000px);

    @media (min-width: 576px) { // RED (SM)
        margin-top: 100px;
    }
    @media (min-width: 768px) { // GREEN (MD)

    }
    @media (min-width: 992px) { // BLUE (LG)

    }
    @media (min-width: 1200px) { // YELLOW (XL)

    }
    @media (min-width: 1400px) { // PURPLE (XXL)

    }
}

</style>